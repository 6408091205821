import React, { useEffect, useState } from "react";
import "./label.scss";  // Assuming you have this file for your styles
import Headernav from "../header_nav/Headernav";
import { Radio, RadioGroup, FormControlLabel, FormControl, Button, Box, Autocomplete, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import axios from "axios";

const Label_print = () => {
  const [jsdata, setJsData] = useState([]);
  const [title, setTitle] = useState("ஏதேனும் ஒன்றை தேர்ந்தெடுத்து PRINTஐ அழுத்தவும்");
  const [cityCheck, setCityCheck] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [cityData, setCityData] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const printbtn = () => {
    window.print();
  };

  // Handle radio change and fetch data accordingly
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'option1') {
      setCityCheck(false);
      setTitle("அனைத்து உறுப்பினர்கள் விவரங்கள் இருக்கும்");
      axios.get("https://www.minttech.in/pethanatchi/hello_wel86768767come_eac87356h_and_98676256every_member_profile_.php")
        .then(response => {
          setJsData(response.data);
        })
        .catch(error => console.log(error));
    } else if (event.target.value === 'option2') {
      setCityCheck(false);
      setTitle("உறுப்பினர்களை தவிர்த்து மற்ற பொறுப்புகளில் உள்ளவர்கள்");
      axios.get("https://www.minttech.in/pethanatchi/ubyuy_873rg6t776fgyfw63rb7364rtn_wft73f-38wyd7w3gnf3fiwnf3y77knikjgsndfyv.php")
        .then(response => {
          setJsData(response.data);
        })
        .catch(error => console.log(error));
    } else {
      setCityCheck(true);
    }
  };

  // Fetch city data once on component load
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('https://www.minttech.in/pethanatchi/78htc6w4_Get_sudyvgew7_City9879.php'); // Adjust URL to your server
        setCityData(response.data); // Assuming response is a JSON array of city names
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  // Fetch city-specific data when city is selected
  useEffect(() => {
    if (inputValue) {
      const fetchCityPeople = async () => {
        try {
          const response = await axios.get(`https://www.minttech.in/pethanatchi/ys5d6b6sd5v7ew6rv3_rt36n87ntf863b3tf-Get_sdhufmjdbf-City_79f7y943selectedsidufng7et77.php?cityname=${inputValue}`);
          setJsData(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchCityPeople();
    }
  }, [inputValue]);

  // Group data into pairs of two
  const groupedData = [];
  for (let i = 0; i < jsdata?.length; i += 2) {
    groupedData.push(jsdata.slice(i, i + 2));
  }

  return (
    <>
      <div>
        <div className="nav">
          <Headernav />

          <Box display="flex" justifyContent="center" alignItems="center" margin="2rem">
            <FormControl className="print_comp" component="fieldset">
              <Stack direction="row" spacing={2} alignItems="center">
                <RadioGroup
                  row
                  aria-label="data"
                  name="data"
                  value={selectedValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel value="option1" control={<Radio />} label="அனைவரும்" />
                  <FormControlLabel value="option2" control={<Radio />} label="நிர்வாக உறுப்பினர்கள்" />
                  <FormControlLabel value="option3" control={<Radio />} label="ஊர்" />
                </RadioGroup>
                <Button variant="contained" color="primary" onClick={printbtn}>
                  Print
                </Button>
              </Stack>
            </FormControl>
          </Box>

          {cityCheck && (
            <Box
           
  justifyContent="center" 
  alignItems="center" 
  width="80%"        // This ensures the box takes 80% of the available width
  maxWidth="900px"   // Maximum width set to 900px, adjust if necessary
  margin="10px auto"    // Centers the box horizontally
             >
              <Autocomplete
                options={cityData} 
                getOptionLabel={(option) => option} // Displays city name
                value={inputValue}
                onChange={(event, newValue) => setInputValue(newValue)} // Handles selection
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Select City" 
                    variant="outlined" 
                  />
                )}
                filterSelectedOptions
                freeSolo // Allows the user to type freely
              />
            </Box>
          )}

          {/* <h2 className="option_title">{title}{inputValue}</h2> */}
        </div>

        <div className="print-container">
          <div>
            <table className="data-table">
              <thead></thead>
              <tbody>
            
          
              {groupedData?.map((group, i) => (
                group?
                  <tr key={i}>
                  {group?.map((a, index) => (
                      <td key={index} className="table-cell">
                        {a.member_name} &nbsp;&nbsp;&nbsp;
                        <span className="member-id">{a.member_no}</span>  <br />
                        s/o {a.father_name} <br />
                        {a.house_name === "null" ? "" : a.house_name + ","}
                        {a.house_name === "null" ? "" : <br></br>}
                        {a.door_no === "null" ? "" : a.door_no+"," }{a.street},{a.area === "null" ? "" : <><br/>{a.area + ","}</>} <br />{a.city}<br/>
                        {a.district} -<span className="phone_font"> {a.pincode}</span><br />
                        போன் : &nbsp;<span className="phone_font">
                          {a.member_mobile}{a.member_alternative_mobile === "null" ? "" : "," + a.member_alternative_mobile} 
                        </span><br />
                      </td>
                    ))}
                    {group?.length === 1 && <td className="table-cell"></td>} {/* Empty <td> for odd number of items */}
                  </tr> :"No Data"
                ))} 
              
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Label_print;
