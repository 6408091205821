import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpFromBracket, faFloppyDisk, faImage, faL, faTrash } from "@fortawesome/free-solid-svg-icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import "./Member_Detail.scss";
import Headernav from "../header_nav/Headernav";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



let Member_detail = () => {
    const { id } = useParams();
    const [previewImage, setPreviewImage] = useState("https://www.minttech.in/pethanatchi/member_photos/blank_passport_size_photo.jpg");
    const [selectedFile, setSelectedFile] = useState();
    let [member_specific, set_member_specific] = useState();
    let [alert_message,set_alert_message]=useState("ALERT⚠️");
    const [open, setOpen] = useState(false);
    let [form_validation_message, set_form_validation_message] = useState("");
    let [checkr_dev, set_check_dev] = useState();
    let [member_no, set_member_no] = useState();
    let [join_date, set_join_date] = useState('');
    let [member_name, set_member_name] = useState();
    let [father_name, set_father_name] = useState();
    let [gender, set_gender] = useState('male');
    let [blood_group, set_blood_group] = useState('null');
    let [dob, set_dob] = useState();
    let [member_age, set_member_age] = useState();
    let [aadhar_no, set_aadhar_no] = useState();
    let [member_live_status, set_member_live_status] = useState('alive');
    let [pre_live_status,set_pre_live_status]=useState()
    let [phone_no, set_phone_no] = useState();
    let [whatsapp_no, set_whatsapp_no] = useState();
    let [whatsapp_checkbox, set_whatsapp_checkbox] = useState(false);
    let [alternative_phone, set_alternative_phone] = useState();
    let [gmail_address, set_gmail_address] = useState();
    let [home_name, set_home_name] = useState();
    let [door_no, set_door_no] = useState();
    let [street, set_street] = useState();
    let [area, set_area] = useState();
    let [city, set_city] = useState();
    let [pincode, set_pincode] = useState();
    let [country, set_country] = useState();
    let [state, set_state] = useState();
    let [district, set_district] = useState();
    let [member_position, set_member_position] = useState('member');
    let [member_profession, set_member_profession] = useState();
    let [father_id_no, set_father_id_no] = useState();
    let [member_details, set_member_details] = useState();
    let [pre_member_details,set_pre_member_details]=useState();
    let [member_no_check,set_member_no_check]=useState("⚠️");
    let [savebuttondisable,set_savebuttondisable]=useState(false);
    const [dbJoinDate, setDbJoinDate] = useState();
    const [out,set_out]=useState(0);
    const [dbdob,set_dbdob]=useState();
    let navigate=useNavigate();
    useEffect(() => {
        fetchMembers(id);
    }, []);
    

    let fetchMembers =  (member_id) => {
        try {
            axios.get(`https://minttech.in/pethanatchi/fetch_specific_member_detail.php?member_id=${member_id}`)
                .then((response) => {
                    set_member_specific(response.data);
                    console.log(response.data);
                    set_member_no(response.data[0].member_no);
                    set_join_date(response.data[0].member_joined_date);
                    set_member_name(response.data[0].member_name);
                    set_father_name(response.data[0].father_name)
                    set_gender(response.data[0].member_gender);
                    set_blood_group(response.data[0].member_blood_group);
                    set_member_age(response.data[0].member_age);
                    set_dob(response.data[0].member_dob);
                    set_member_age(response.data[0].member_age);
                    set_aadhar_no(response.data[0].member_aadhar);
                    set_member_live_status(response.data[0].member_current_status);
                    set_pre_live_status(response.data[0].member_current_status)
                    set_phone_no(response.data[0].member_mobile);
                    response.data[0].member_whatsapp=="null"?set_whatsapp_no(""):set_whatsapp_no(response.data[0].member_whatsapp);
                    response.data[0].member_alternative_mobile=="null"?set_alternative_phone(""):set_alternative_phone(response.data[0].member_alternative_mobile);
                    response.data[0].member_mail=="null"?set_gmail_address(""):set_gmail_address(response.data[0].member_mail);
                    response.data[0].photo_path==null?setPreviewImage("https://www.minttech.in/pethanatchi/images/blank_passport_size_photo.jpg"):setPreviewImage(response.data[0].photo_path);
                    response.data[0].photo_path==null?setSelectedFile("https://www.minttech.in/pethanatchi/images/blank_passport_size_photo.jpg"):setSelectedFile(response.data[0].photo_path);
                    // response.data[0].photo_path==null?setPreviewImage("https://www.minttech.in/pethanatchi/images/blank_passport_size_photo.jpg"):setPreviewImage(response.data[0].photo_path+`?${new Date().getTime()}`);
                    // response.data[0].photo_path==null?setSelectedFile("https://www.minttech.in/pethanatchi/images/blank_passport_size_photo.jpg"):setSelectedFile(response.data[0].photo_path+`?${new Date().getTime()}`);
                    response.data[0].house_name=="null"?set_home_name(""):set_home_name(response.data[0].house_name);
                    set_door_no(response.data[0].door_no);
                    set_street(response.data[0].street);
                    response.data[0].area=="null"?set_area(""):set_area(response.data[0].area);
                    set_city(response.data[0].city);
                    set_pincode(response.data[0].pincode);
                    set_country(response.data[0].nationality);
                    set_state(response.data[0].state);
                    set_district(response.data[0].district);
                    set_member_position(response.data[0].position);
                    response.data[0].occupation=="null"?set_member_profession(""):set_member_profession(response.data[0].occupation);
                    response.data[0].fathers_member_no=="null"?set_father_id_no(""):set_father_id_no(response.data[0].fathers_member_no);
                    response.data[0].description=="null"?set_member_details(""):set_member_details(response.data[0].description);
                    response.data[0].description=="null"?set_pre_member_details(""):set_pre_member_details(response.data[0].description);
                }
                )

        } catch (error) {
            console.log(error);

        }

    }
    useEffect(()=>{

        if(phone_no&&whatsapp_no)
        {
            if(phone_no==whatsapp_no){
                set_whatsapp_checkbox(true);
            }
            else
            {
                set_whatsapp_checkbox(false);
            }
           
        }
    },[phone_no,whatsapp_no])
   
    
    const bloodGroups = [
        { value: 'null', label: 'தெரியவில்லை' },
        { value: 'A+', label: 'A Positive (A+)' },
        { value: 'A-', label: 'A Negative (A−)' },
        { value: 'B+', label: 'B Positive (B+)' },
        { value: 'B-', label: 'B Negative (B−)' },
        { value: 'AB+', label: 'AB Positive (AB+)' },
        { value: 'AB-', label: 'AB Negative (AB−)' },
        { value: 'O+', label: 'O Positive (O+)' },
        { value: 'O-', label: 'O Negative (O−)' },
    ];
    
    

    let dob_finder = (e) => {
        const date = new Date(e.target.value);
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;

        const today = new Date();
        let age = today.getFullYear() - date.getFullYear();
        const monthDifference = today.getMonth() - date.getMonth();
        const dayDifference = today.getDate() - date.getDate();


        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }

        set_dob(e.target.value);
        set_member_age(age);
    }
    let whatscheck = (e) => {
        const isChecked = e.target.checked;
        console.log(isChecked);
        if (isChecked) {
            set_whatsapp_no(phone_no);
           
            
        }
        else {
            set_whatsapp_no('');
            set_whatsapp_checkbox(false);
        }

    }
    let fileInputRef = useRef(null);
    let clicktoopenfile = () => {
        fileInputRef.current.click();
    }
    let handleFileChange = (e) => {
        let file = e.target.files[0];
        console.log(file);
        
       if (file) {
           // Create a preview URL for the selected image
           const imageUrl = URL.createObjectURL(file);
           setPreviewImage(imageUrl);
           setSelectedFile(file); 
       }

   }
   let update_member_formsubmit = async  () => {
    var fin_result = "";
    
    if (!member_no) {
        fin_result += " உறுப்பினர் எண்,";
    }
    if (!member_name) {
        fin_result += "உறுப்பினர் பெயர்,";
    }
    if (!dob) {
        fin_result += "பிறந்த தேதி,";
    }
    if(!aadhar_no)
    {
        fin_result += "ஆதார் எண்,";
    }
    if (!phone_no) {
        fin_result += "அலைபேசி எண்,";
    }
    
    if (!door_no) {
        fin_result += "கதவு எண்,";
    }
    if (!street) {
        fin_result += "தெரு/சாலை,";
    }
    if (!city) {
        fin_result += "நகரம்,";
    }
    if (!pincode) {
        fin_result += "பின்கோடு,";
    }
    if (!country) {
        fin_result += "நாடு,";
    }
    if (!state) {
        fin_result += "மாநிலம்,";
    }
    if (!district) {
        fin_result += "மாவட்டம்,";
    }
    if(pre_live_status===member_live_status)
    {
       
    }
    else
    {
        if(member_details)
            {
                if(member_details==pre_member_details)
                {
                    fin_result +="(,) சேர்த்து மாற்றப்பட்ட விபரத்தை உள்ளிடவும் ";
                }
            }
            else
            {
                fin_result +=`நிலை மாற்றம் செய்யப்பட்டால் விபரம் கண்டிப்பாக குடுக்க வேண்டும் ${pre_live_status}`;
            } 
    }
    if (fin_result) {
        fin_result = removeLastComma(fin_result);
        // alert(fin_result+" இவை அனைத்தும் கண்டிப்பாக நிரப்ப வேண்டும் . ");
        set_form_validation_message(fin_result);
        set_out(1);
        handleClickOpen();
    }
    else {
        set_out(0);
        let fd=new FormData();
        
        fd.append('member_number',member_no);
        fd.append('member_name',member_name);
        father_name? fd.append('father_name',father_name): fd.append('father_name',null);
        fd.append('member_gender',gender);
        fd.append('member_blood_group',blood_group);
        fd.append('member_dob',dob);
        fd.append('member_age',member_age);
        fd.append('member_aadhar',aadhar_no);
        join_date?fd.append('member_joined_date',join_date):fd.append('member_joined_date',null);
        fd.append('member_current_status',member_live_status);
        fd.append('member_mobile',phone_no);
        whatsapp_no?fd.append('member_whatsapp',whatsapp_no):fd.append('member_whatsapp',null);
        alternative_phone?fd.append('member_alternative_mobile',alternative_phone):fd.append('member_alternative_mobile',null);
        gmail_address? fd.append('member_mail',gmail_address): fd.append('member_mail',null);
        
        home_name?fd.append('house_name',home_name):fd.append('house_name',null);
        fd.append('door_no',door_no);
        fd.append('street',street);
        area?fd.append('area',area):fd.append('area',null);
        fd.append('city',city);
        fd.append('pincode',pincode);
        fd.append('nationality',country);
        fd.append('state',state);
        fd.append('district',district);
        fd.append('position',member_position);
        member_profession?fd.append('occupation',member_profession):fd.append('occupation',null);
        father_id_no?fd.append('fathers_member_no',father_id_no): fd.append('fathers_member_no',null);
        member_details?fd.append('description',member_details):fd.append('description',null);
       
        
        fd.append('created_by',localStorage.getItem('user')+"("+localStorage.getItem('user_role')+")");
        fd.append('created_date',getCurrentDate());
        fd.append('created_time',getCurrentTime());
        fd.append('photo_path',selectedFile);
        fd.append('status',null);
       
        await axios.post("https://minttech.in/pethanatchi/update_members_bio_data.php",fd)
        .then(response=>console.log(response.data))
        .catch(error=>console.log(error));
        set_alert_message("UPDATED ✅");
        set_form_validation_message("மாற்றம் செய்யப்பட்டது ");
        await handleClickOpen();
        
        
       
    }
    


}
function removeLastComma(str) {
    // Check if the string ends with a comma
    if (str.endsWith(',')) {
        // Remove the last character (the comma)
        return str.slice(0, -1);
    }
    // Return the string unchanged if no comma is found
    return str;
}
const handleClickOpen = () => {
    setOpen(true);
};

const handleClose = () => {
    setOpen(false);
    if(out==0)
    {
        navigate('/');
    }
    
    set_alert_message("ALERT⚠️")
};
const getCurrentDate = () => {
    const now = new Date();

    const day = ('0' + now.getDate()).slice(-2);
    const month = ('0' + (now.getMonth() + 1)).slice(-2); // Months are 0-based
    const year = now.getFullYear();

    return `${day}-${month}-${year}`;
}
const getCurrentTime = () => {
    const now = new Date();

    const hours = ('0' + now.getHours()).slice(-2);
    const minutes = ('0' + now.getMinutes()).slice(-2);

    return `${hours}:${minutes}`;
}

const convertToInputDateFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`; // return in YYYY-MM-DD format
  };

  const handleBlur = () => {
    // Perform some function only after the user moves to the next field
    console.log("Input field filled: ", member_name);
    // You can add validation, API calls, etc. here
  };
    return (
        <div>
           <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{alert_message}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <span style={{ fontWeight: "bolder" }} >{form_validation_message} </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button style={{ fontWeight: "bolder", color: "orangered" }} onClick={handleClose}>சரி </Button>
                </DialogActions>
            </Dialog>
            <Headernav />
            <Grid container spacing={1} className="main-container" >

<Grid item xs={6} >
    <fieldset className="personal_information_fs" >
        <legend>
            <Typography variant="h6" className="legend_font">சொந்த விவரங்கள்</Typography>
        </legend>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={3}>
                <Typography variant="h6" className="form_label_font" >
                    உறுப்பினர் எண்
                    <Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={3} >
                <input type="number" value={member_no} readOnly className="input_field" />
               
            </Grid>
            <Grid item xs={1}>
            <Tooltip title="❌ உறுப்பினர் எண் உள்ளிடபட்டது">{member_no_check}</Tooltip> 
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">
                    தேதி
                </Typography>
            </Grid>
            <Grid item xs={3} >
                <input type="date" value={join_date} readOnly onChange={(e) => {
                    set_join_date(e.target.value)
                }} className="input_field input_field_date" />
            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={3}>
                <Typography variant="h6" className="form_label_font" >
                    பெயர் <Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={9} >
                <input type="text" value={member_name} onBlur={handleBlur} onChange={(e) => set_member_name(e.target.value)} className="input_field" />

            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={3}>
                <Typography variant="h6" className="form_label_font" >
                    தந்தையின் பெயர்
                </Typography>
            </Grid>
            <Grid item xs={9} >
                <input type="text" value={father_name} onChange={(e) => set_father_name(e.target.value)} className="input_field" />

            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={3}>
                <Typography variant="h6" className="form_label_font" >
                    பாலினம்
                </Typography>
            </Grid>
            <Grid item xs={3} >
                <select value={gender} onChange={(e) => set_gender(e.target.value)} className="input_field">
                    <option value="male">ஆண்</option>
                    <option value="female">பெண்</option>
                </select>

            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">
                    இரத்தம்
                </Typography>
            </Grid>
            <Grid item xs={4} >
                <select value={blood_group} onChange={(e) => set_blood_group(e.target.value)} className="input_field">

                    {bloodGroups.map(group => (
                        <option
                            key={group.value}
                            value={group.value}
                            disabled={group.disabled}
                        >
                            {group.label}
                        </option>
                    ))}
                </select>

            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={3}>
                <Typography variant="h6" className="form_label_font" >
                    பிறந்த தேதி <Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={3} >

                <input type="date" value={dob} onBlur={dob_finder} onChange={(e)=>set_dob(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">
                    வயது
                </Typography>
            </Grid>
            <Grid item xs={4} >
                <input value={member_age} type="number" disabled className="input_field" />
            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={3}>
                <Typography variant="h6" className="form_label_font" >
                    ஆதார் எண் <Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={3} >

                <input type="number" value={aadhar_no} onChange={(e) => set_aadhar_no(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">
                    நிலை
                </Typography>
            </Grid>
            <Grid item xs={4} >
                <select value={member_live_status} onChange={(e) => set_member_live_status(e.target.value)} className="input_field">
                    <option value="alive">செயலில் இருக்கிறார்</option>
                    <option value="removed">நீக்கப்பட்டார் </option>
                    <option value="expired">காலமாகிவிட்டார் </option>

                </select>

            </Grid>
        </Grid>
    </fieldset>
</Grid>
<Grid item xs={4}>
    <fieldset className="personal_information_fs" >
        <legend>
            <Typography variant="h6" className="legend_font">தொடர்பு விபரங்கள்</Typography>
        </legend>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    அலைபேசி எண் <Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={5} >

                <input type="number" value={phone_no} onChange={(e) => set_phone_no(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">

                </Typography>
            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    வாட்சப் எண்

                </Typography>

            </Grid>
            <Grid item xs={5} >

                <input type="number" value={whatsapp_no} onChange={(e) => set_whatsapp_no(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={2}>


                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { fontSize: '11px' } }} style={{ color: "black" }} control={<Checkbox checked={whatsapp_checkbox} onChange={whatscheck} style={{ color: "red" }} size="small" />} label="same" />

            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    அலைபேசி எண்(1)
                </Typography>
            </Grid>
            <Grid item xs={5} >

                <input type="number" value={alternative_phone} onChange={(e) => set_alternative_phone(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">

                </Typography>
            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    மின்னஞ்சல்
                </Typography>
            </Grid>
            <Grid item xs={5} >

                <input type="email" value={gmail_address} onChange={(e) => set_gmail_address(e.target.value)} className="input_field input_field_date" />
           
            </Grid>
            <Grid item xs={2}>
                <Typography variant="h6" className="form_label_font">

                </Typography>
            </Grid>

        </Grid>
    </fieldset>
</Grid>
<Grid item xs={2}>
    <fieldset className="personal_information_fs_photo" >
        <legend >
            <Typography variant="h6" className="legend_font">புகைப்படம் </Typography>
        </legend>

        <Grid className="general_grid_for_image" container >

            <Grid item>
                <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
                    <img className="member_profile_photo" src={previewImage} />
                    <Button
                        size="small"
                        color="info"
                        variant="contained"
                        endIcon={<FontAwesomeIcon icon={faImage} />}
                        onClick={clicktoopenfile}
                    >
                        UPLOAD PHOTO
                    </Button>
                    <input
                        type="file"
                        ref={fileInputRef}
                        hidden
                        onChange={handleFileChange}
                    />
                    


                </Box>

            </Grid>


        </Grid>

    </fieldset>

</Grid>
</Grid>
<Grid container spacing={1} className="main-container" >
<Grid item xs={6}>
    <fieldset className="personal_information_fs" >
        <legend>
            <Typography variant="h6" className="legend_font">முகவரி</Typography>
        </legend>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    வீட்டின்/நிறுவனத்தின் பெயர்<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <input type="text" value={home_name} onChange={(e) => set_home_name(e.target.value)} className="input_field input_field_date" />

            </Grid>


        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    கதவு எண்<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip> & தெரு/சாலை<span className="mandatory_field">*</span>
                </Typography>
            </Grid>
            <Grid item xs={3} >

                <input type="text" value={door_no} onChange={(e) => set_door_no(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={3} >

                <input type="text" value={street} onChange={(e) => set_street(e.target.value)} className="input_field input_field_date" />

            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    பகுதி
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <input type="text" value={area} onChange={(e) => set_area(e.target.value)} className="input_field input_field_date" />

            </Grid>


        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    நகரம்<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>  & பின்கோடு<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={3} >

                <input type="text" value={city} onChange={(e) => set_city(e.target.value)} className="input_field input_field_date" />

            </Grid>
            <Grid item xs={3} >

                <input type="number" value={pincode} onChange={(e) => set_pincode(e.target.value)} className="input_field input_field_date" />
            </Grid>

        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    நாடு<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <input type="text" value={country} onChange={(e) => set_country(e.target.value)} className="input_field input_field_date" />

            </Grid>


        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    மாநிலம்<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <input type="text" value={state} onChange={(e) => set_state(e.target.value)} className="input_field" />

            </Grid>


        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    மாவட்டம்<Tooltip title="கண்டிப்பாக நிரப்ப வேண்டும்✅"> <span className="mandatory_field">*</span></Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <input type="text" value={district} onChange={(e) => set_district(e.target.value)} className="input_field" />

            </Grid>


        </Grid>
    </fieldset>
</Grid>
<Grid item xs={6}>
    <fieldset className="personal_information_fs" >
        <legend>
            <Typography variant="h6" className="legend_font">பிற தகவல்</Typography>
        </legend>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    பொறுப்பு
                </Typography>
            </Grid>
            <Grid item xs={6} >
            <select value={member_position} onChange={(e) => set_member_position(e.target.value)} className="input_field">
            <option value="chairman" >தலைவர்</option>
            <option value="vice_president">துணை தலைவர்</option>
            <option value="porulaalar">பொருளாளர்</option>
            <option value="secretary">செயலாளர்</option>
            <option value="joint_secretary">இணைச் செயலாளர்</option>
            <option value="ec_member">நிர்வாக குழு உறுப்பினர்</option>
            <option value="member">உறுப்பினர்</option>
            
            </select>
           

               

            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    தொழில்
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <input type="text" value={member_profession} onChange={(e) => set_member_profession(e.target.value)} className="input_field" />

            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Tooltip title="தந்தை வாரிசு எண்  இருந்தால் உள்ளிடவும் இல்லை என்றால் அதை நிரப்ப வேண்டாம் ">
                    <Typography variant="h6" className="form_label_font" >
                        தந்தை உறுப்பினர் எண்
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={6} >
                <input type="text" value={father_id_no} onChange={(e) => set_father_id_no(e.target.value)} className="input_field" />

            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={5}>
                <Typography variant="h6" className="form_label_font" >
                    விபரம்
                </Typography>
            </Grid>
            <Grid item xs={6} >

                <TextField
                    className="input_field_text_area"
                    value={member_details} onChange={(e) => set_member_details(e.target.value)}
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: 'orangered', // Change this to your desired color
                            },
                        },
                        '& .MuiInputLabel-root': {
                            '&.Mui-focused': {
                                color: 'orangered', // Change this to your desired color
                            },
                        },
                    }}
                />

            </Grid>
        </Grid>
        <Grid className="general_grid" container spacing={2} columns={12}>
            <Grid item xs={9}  >
            </Grid>
            <Grid item xs={3}  >

                <Button
                    disabled={savebuttondisable}
                    size="small"
                    color="success"
                    variant="contained"
                    
                    onClick={update_member_formsubmit}
                    startIcon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}
                >
                    Update
                </Button>
            </Grid>
        </Grid>

    </fieldset>
</Grid>
</Grid>
        </div>
    );
}

export default Member_detail;