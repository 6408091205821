import React, { useEffect, useState } from "react";
import Headernav from "../header_nav/Headernav";
import { Alert, FormControlLabel, Snackbar, Switch } from "@mui/material";
import "./my_profile.scss"
import axios from "axios";
import { useLocation } from "react-router-dom";


let My_profile = () => {
  let [res, setres] = useState([]);
  let [id, setid] = useState();
  let [name, set_name] = useState("loading..");
  let [username, set_username] = useState("loading..");
  let [password, set_password] = useState("loading..");
  const [checked, setChecked] = useState(false);
  let [upadate_alert, set_update_alert] = useState(false);
  const location = useLocation();
  useEffect(() => {
    let getuserdetails = async () => {
      let fd = new FormData();
      fd.append('username', localStorage.getItem('user_name'));
      await axios.post("https://minttech.in/pethanatchi/my_profile.php", fd)
        .then(response => {
          setres(response.data)
          setuserdetail(response.data);
        })
        .catch(error => console.log(error));
    }
    getuserdetails();

    let setuserdetail = (k) => {
      k.map((e) => {
        set_name(e.name);
        set_username(e.username);
        set_password(e.password);
        setid(e.id);
      })
    }
  }, [location.pathname])

  const handleSubmit = (e) => {

    e.preventDefault();


    if (!name) {
      alert("Name Field is empty");
    }
    else if (!username) {
      alert("username Field is empty");
    }
    else if (!password) {
      alert("password Field is empty");
    }
    else {


      let idpass = new FormData();
      idpass.append('user_id', id);
      idpass.append('user_name', name);
      idpass.append('user_username', username);
      idpass.append('user_password', password);
      axios.post("https://minttech.in/pethanatchi/profile_update.php", idpass)
        .then(response => console.log(response.data))
        .catch(error => console.log(error))
      set_update_alert(true);
      setChecked(false);

    }
  }
  const handleChange = () => {
    setChecked(!checked);
  };
  let handleClose = () => {

    set_update_alert(false);
  }
  return (
    <>
      <Headernav />

      <div className='form-det'>

        <div className="form-container">
          <p className="profile_heading">PROFILE</p>
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleChange}
              sx={{

                '& .MuiSwitch-switchBase': {
                  color: 'red',
                },
                '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                  backgroundColor: 'red',
                },
              }}
            />}
            label={checked ? "UPDATE செய்யலாம் ✅" : "👈UPDATE செய்ய நகர்த்தவும்"}
            sx={{
              '& .MuiFormControlLabel-label': {
                color: checked ? 'green' : 'black',
                fontWeight: 'bold'
              },
            }}
          />
          <form onSubmit={handleSubmit} className="form">
            <label className="form-label">
              Name:
              <input
                type="text"
                name="name"
                onChange={(e) => set_name(e.target.value)}
                value={name}
                disabled={!checked}
                className="form-input"
              />
            </label>
            <label className="form-label">
              Username:
              <input
                type="text"
                name="username"
                value={username}
                onChange={(e) => set_username(e.target.value)}
                disabled
                className="form-input"
              />
            </label>
            <label className="form-label">
              Password:
              <input
                type={checked ? "text" : "password"}
                name="password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
                disabled={!checked}
                className="form-input"
              />
            </label>

            {
              checked ? <button type="submit" className="form-button">UPDATE</button> : ""
            }

          </form>
        </div>
        <Snackbar open={upadate_alert} autoHideDuration={3000} onClose={handleClose} >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Update Successfully
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}
export default My_profile;