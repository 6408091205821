import React, { useEffect, useState } from "react";
import './Admin_table.scss';
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { faL } from "@fortawesome/free-solid-svg-icons";
let Admin_table=()=>{


    useEffect(()=>{
      setLoading(true);
       axios.get("https://www.minttech.in/pethanatchi/admin_details.php")
        .then(response=>{
          set_admin_data(response.data)
          set_filter_data(response.data)
        })
        .catch(error=>console.log(error));
        setLoading(false);

    },[])

    let [admin_data,set_admin_data]=useState([]);
    let [filter_data,set_filter_data]=useState([]);
    let [loading, setLoading] = useState(false); // Loading state



    let filterthedata=(e)=>{
      let searchdata=e.target.value.toLowerCase();
      let fil_data=admin_data.filter((user)=>user.name.toLowerCase().includes(searchdata) || user.id.includes(searchdata) || user.role.toLowerCase().includes(searchdata) || user.username.toLowerCase().includes(searchdata) || user.created_date.toLowerCase().includes(searchdata));
      set_filter_data(fil_data);
      
    }


    return(
        <div className="table-container">

        <div className="input_search">
        <input type="search" placeholder="Search here...." onChange={filterthedata} />
        </div>
        
        <table className="styled-table">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>NAME</th>
              <th>USER_NAME</th>
              <th>PASSWORD</th>
              <th>ROLE</th>
              <th>DATE & TIME</th>

            </tr>
          </thead>
          <tbody>
          {loading && <ClipLoader size={24} color={"orangered"} />}
            {filter_data.map((row, index) => (
              <tr key={index}>
                <td>{index+1}</td>
                <td>{row.name }</td>
                <td>{row.username}</td>
                <td>{row.password}</td>
                <td>{row.role}</td>
                <td>{row.created_date}  {row.created_time}</td>
              </tr>
            ))}
            
          </tbody>
        </table>
       
      </div>
    )
}
export default Admin_table;