import React from "react";
import { useNavigate } from "react-router-dom";
import Headernav from "../header_nav/Headernav";
import './dashboardadmins.scss'
import Add_members from "../Members_func/Add_members";
import { Margin } from "@mui/icons-material";


let Dashboard_super_admin =()=>{
    let navigate=useNavigate();
    
    return(
        <div>
        <Headernav/>
        <Add_members/>
        </div>
    );
}

export default Dashboard_super_admin;