import { useState } from 'react';
import './App.scss';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners'; // Import the loading spinner
import { Backdrop, CircularProgress } from '@mui/material';

function App() {
  let [username, set_username] = useState('');
  let [pass, set_pass] = useState('');
  let [result, set_result] = useState([]);
  let [loading, setLoading] = useState(false); // Loading state

  let navigate = useNavigate();

  let handlesub = () => {
    if (!username) {
      alert("Please enter the username");
    } else if (!pass) {
      alert("Please enter the password");
    } else {
      setLoading(true); // Set loading to true when request starts
      let formdata = new FormData();
      formdata.append('u_name', username);
      formdata.append('u_pass', pass);
      axios.post("https://minttech.in/pethanatchi/login_check.php", formdata)
        .then(response => {
          set_result(response.data);
          console.log(response);
          setLoading(false); // Set loading to false when request completes
          if (response.data === false) {
            alert("Incorrect Username or Password");
          } else {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', response.data.user_Name);
            localStorage.setItem('user_role', response.data.role);
            localStorage.setItem('user_name',response.data.username_from_database);
            navigate('/');
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false); // Set loading to false when request completes
        });
    }
  };

  return (
    <>
      <div className="Login_container">
        <div className='login_box'>
          <table className='login_table'>
            <tr>
              <th colSpan={2} style={{ fontFamily: "monospace", fontSize: "30px" }}>LOGIN</th>
            </tr>
            <tr>
              <th><label style={{ fontFamily: "sans-serif" }}>USERNAME</label></th>
              <th> <input type='text' value={username} onChange={(e) => set_username(e.target.value)} /> </th>
            </tr>
            <tr>
              <th><label style={{ fontFamily: "sans-serif" }}>PASSWORD</label></th>
              <th> <input type='password' value={pass} onChange={(e) => set_pass(e.target.value)} /> </th>
            </tr>
            <tr className='button_center'>
              <th colSpan={2}>
                <input onClick={handlesub} type='button' value="LOGIN" disabled={loading} />
                {loading && <ClipLoader size={24} color={"orangered"} />}
              </th>
            </tr>
          </table>
        </div>
      </div>

    </>
  );
}

export default App;
