import React, { useState } from "react";
import Headernav from "../header_nav/Headernav";
import './Admin.scss'

import Admin_table from "./Admin_table";
import { Tooltip } from "@mui/material";
import Admin_add from "./Admin_add";




let Admin=()=>{

    let [show,setshow]=useState(true);
    let acticecheck=()=>{
        setshow(!show);
    }
  
      
    return(
        <div className="Admin_module">
        <Headernav/>
        <div className="Admin_Base">

        { show ? <Tooltip arrow  title="ADMINஐ உருவாக்குதல்" > <button onClick={acticecheck} className="admin_btn">ADD ADMIN</button> </Tooltip> :  <Tooltip arrow  title="ADMIN விவரங்கள் " > <button onClick={acticecheck} className="admin_btn">ADMIN USERS</button></Tooltip> }

       { show ? <div className="admin_datagrid" ><Admin_table /></div>: <div className="admin_Add"><Admin_add/></div> }   
      
            
        </div>

        </div>
    );
}

export default Admin;