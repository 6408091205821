import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import ProtectedRoute from "../ProtectedRoute";
import Roughtest from "../Rough_dev_test";
import Dashboard_super_admin from "../super_admin_dash/Dashboard_super_admin";
import Admin from "../Admin_operation/Admin";
import My_profile from "../my_profile/my_profile";
import Label_print from "../label_printing/label";
import Home_page from "../Home_page/Home";
import Member_detail from "../Member_detail_view/Member_Detail";



let Routing=()=>{
    return(
        <BrowserRouter>
        
            <Routes>
                <Route element={<App/>} path="/login"></Route>
            <Route element={<Roughtest/>} path="/hello" ></Route>
            <Route element={<ProtectedRoute><Member_detail/></ProtectedRoute>} path="/members-details/:id"></Route>
            <Route element={<ProtectedRoute><Label_print/></ProtectedRoute>} path="/print-the-member-label-for-post"></Route>
                <Route element={<ProtectedRoute><Dashboard_super_admin/></ProtectedRoute>} path="/addmember"></Route>
                <Route element={<ProtectedRoute><Admin/></ProtectedRoute>} path="/admin-details" ></Route>
                <Route element={<ProtectedRoute><My_profile/></ProtectedRoute>} path="/my-profile" ></Route>
                <Route element={<ProtectedRoute><Home_page/></ProtectedRoute>} path="/" ></Route>
            </Routes>
            
        </BrowserRouter>
    );
}

export default Routing;
