import React, { useEffect, useState } from "react";
import './Headernav.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons/faUserGroup";
import { faHouse, faIdCard, faPowerOff, faPrint, faUserPlus, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Label_print from "../label_printing/label";



let Headernav=()=>{
    
    let navigate=useNavigate();
    
    let logout=()=>{
        localStorage.removeItem('token'); 
        localStorage.removeItem('user');
        localStorage.removeItem('user_role');
        localStorage.removeItem('user_name');
        navigate('/login');
    }   
    let adminpage=()=>{
        
        navigate('/admin-details');
        
    }
    let homepage=()=>{
        navigate('/');
     
    }
    let addmember=()=>{
        navigate('/addmember');
    }
   
    return(
        <>
            <div className="nav-box" >
            

            
                <div className="menu-options">
                <div onClick={homepage} className="menus"  >
                        
                        <FontAwesomeIcon className="menus_icon" icon={faHouse}  />
                        <p className="menu_para">HOME</p>
                      
                    </div>
             
                    <div onClick={addmember} className="menus"  >
                        
                        <FontAwesomeIcon className="menus_icon" icon={faUserPlus}  />
                        <p className="menu_para">MEMBER</p>
                      
                    </div>
                  
                    <div className="menus" onClick={()=>{navigate('/print-the-member-label-for-post')}}>
                        <FontAwesomeIcon className="menus_icon" icon={faPrint} />
                        <p className="menu_para">LABEL</p>
                    </div>

                    {
                        localStorage.getItem('user_role')!=="admin" ? <Tooltip   arrow title="ADMIN விவரங்களை பார்க்க மற்றும் சேர்க்கைக்கு இதை அழுத்தவும் ">
                    <div className="menus" onClick={adminpage}   >
                        <FontAwesomeIcon className="menus_icon" icon={faUserTie} />
                        <p className="menu_para">ADMINS</p>
                    </div>
                    </Tooltip> : ""
                    }
                    
                    <Tooltip arrow  title="வேலை முடிந்தபின் இதை அழுத்தி வெளியில் செல்லவும் ">
                    <div className="menus" onClick={logout}>
                      
                        <FontAwesomeIcon style={{color:"red"}}  className="menus_icon" icon={faPowerOff} />
                        <p className="menu_para">LOGOUT</p>
                        
                    </div>
                    </Tooltip>
                  
                    
                  
                  <div className="user_details">
                  <Tooltip arrow  title={localStorage.getItem('user')}>
                    <div className="menus" onClick={()=>navigate('/my-profile')}>
                      
                        <FontAwesomeIcon style={{color:"red"}}  className="menus_icon" icon={faIdCard} />
                        <p className="menu_para">MY PROFILE</p>
                        
                    </div>
                    </Tooltip>
                    
                  </div>
                  <div>
      
    </div>


                </div>
            </div>
        </>
    );
}

export default Headernav;