import React, { useState } from 'react';
import './Admin_add.scss'; // Ensure you have this file for styles
import axios from 'axios';
import { Alert } from '@mui/material';
import { Tune } from '@mui/icons-material';

function Admin_add() {

    let [name,set_name]=useState();
    let [username,set_username]=useState();
    let [password,set_password]=useState();
    let [role,set_role]=useState(`select`);
    let [addadminalert,set_addadminalert]=useState(false);

  
    const getCurrentDate = () => {
        const now = new Date();
    
        const day = ('0' + now.getDate()).slice(-2);
        const month = ('0' + (now.getMonth() + 1)).slice(-2); // Months are 0-based
        const year = now.getFullYear();
    
        return `${day}-${month}-${year}`;
    }
    
    const getCurrentTime = () => {
        const now = new Date();
    
        const hours = ('0' + now.getHours()).slice(-2);
        const minutes = ('0' + now.getMinutes()).slice(-2);
    
        return `${hours}:${minutes}`;
    }
    

  

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    if(!name)
    {
      alert("Name கொடுக்கவில்லை");
    }
    else if(!username)
    {
      alert("username கொடுக்கவில்லை");
    }
    else if(!password)
    {
      alert("password கொடுக்கவில்லை");
    }
    else
    {
    let current_date=getCurrentDate();
    let current_time=getCurrentTime();
    set_addadminalert(true);
    let fd=new FormData();
    fd.append('name',name);
    fd.append('user_name',username);
    fd.append('password',password);
    fd.append('role',role);
    fd.append('c_date',current_date);
    fd.append('c_time',current_time);
   axios.post("https://www.minttech.in/pethanatchi/admin_add.php",fd)
   .then(response=>console.log(response.data))
   .catch(error=>console.log(error));
   set_name("");
   set_username("");
   set_password("");
   set_role("select")
   setTimeout(() => {
    set_addadminalert(false);
  }, 2000);
}

  };

  return (
    <div className='form-det'>
    
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form">
        <label className="form-label">
          Name:
          <input
            type="text"
            name="name"
            onChange={(e)=>set_name(e.target.value)}
            value={name}
            className="form-input"
          />
        </label>
        <label className="form-label">
          Username:
          <input
            type="text"
            name="username"
            value={username}
            onChange={(e)=>set_username(e.target.value)}
            className="form-input"
          />
        </label>
        <label className="form-label">
          Password:
          <input
            type="text"
            name="password"
            value={password}
            onChange={(e)=>set_password(e.target.value)}
            className="form-input"
          />
        </label>
        <label className="form-label">
          Role:
          <select
            name="role"
            value={role}
            onChange={(e)=>set_role(e.target.value)}
            className="form-select"
          >
            <option value="select">Select a role</option>
            <option value="admin">Admin</option>
            <option value="super_admin">Super Admin</option>
       
          </select>
        </label>
        <button type="submit" className="form-button">Submit</button>
      </form>
    </div>
    {addadminalert ? <div className='alert_field'><Alert variant="filled" severity="success"> Admin Added Successfully</Alert></div> : "" }
    </div>
  );
}

export default Admin_add;
