import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField, CircularProgress } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Members_data.scss"

const Member_data = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // Page starts from 0 in MUI DataGrid
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageSize, setPageSize] = useState(25); // Number of records per page
  const [searchTerm, setSearchTerm] = useState(""); // Store the search term

  // Fetch member data from the server
  const fetchMembers = async (page, searchTerm = "") => {
    setLoading(true);
    const offset = page * pageSize;
    try {
      const response = await axios.get(
        `https://www.minttech.in/pethanatchi/fetch_all_member_data.php?limit=${pageSize}&offset=${offset}&search=${searchTerm}`
      );
      const { members, total } = response.data; // Destructure the data
   
      // Map the data into the format required by DataGrid
      const mappedData = members.map((member) => ({
        
       
        id: member.member_no,        // Unique ID
        name: member.member_name,    // Name field
        phone: member.member_mobile,  
        city: member.city,   // Password field
        father: member.father_name,
        position: member.position=="chairman"?"தலைவர்":member.position=="vice_president"?"துணை தலைவர்":member.position=="porulaalar"?"பொருளாளர்":member.position=="secretary"?"செயலாளர்":member.position=="joint_secretary"?"இணைச் செயலாளர்":member.position=="ec_member"?"நிர்வாக குழு உறுப்பினர்":"உறுப்பினர்"
      }));

      setMembers(mappedData);
      setTotalMembers(total); // Update total members count
    } catch (error) {
      console.error("Error fetching members:", error);
    }
    setLoading(false);
  };
  let navigate=useNavigate();
  const handleRowClick = (params) => {
    // Navigate to member details page with member ID
    navigate(`/members-details/${params.id}`);
    // console.log(params.row.name);
    // alert(params.row.name);
    
  };
  // Effect for fetching members when the component mounts or when page/searchTerm changes
  useEffect(() => {
    fetchMembers(page, searchTerm);
  }, [page, searchTerm, pageSize]); // Added pageSize to the dependency array

  // Handler for search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0); // Reset to the first page when searching
  };

  // Define columns for DataGrid
  const columns = [
    { field: "id", headerName: "உறுப்பினர் எண்", width: 150 ,headerClassName: 'bold-header'},
    { field: "name", headerName: "பெயர்", width: 200,headerClassName: 'bold-header' },
    { field: "phone", headerName: "மொபைல் நம்பர்", width: 200,headerClassName: 'bold-header' },
    { field: "city", headerName: "ஊர்", width: 150,headerClassName: 'bold-header' },
    { field: "father", headerName: "தந்தை பெயர்", width: 200,headerClassName: 'bold-header' },
    { field: "position", headerName: "பொறுப்பு", width: 186,headerClassName: 'bold-header'}
  ];

  return (
    <div className="member_data_container" style={{ height: "70vh", width: "85%", marginTop: "1rem", margin: "0 auto" }}>
      {/* Search Input */}
      <TextField
        label="search"
        variant="outlined"
        value={searchTerm}
        placeholder="உறுப்பினர் எண் | பெயர் | நம்பர் | ஊர் | தந்தை(பெயர் | உறுப்பினர் எண்)"
        onChange={handleSearchChange}
        style={{ marginTop: "1rem", marginBottom: "1rem", width: "100%" }}
      />

      {/* Loader and DataGrid */}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
        showCellVerticalBorder
        
        className="custom-data-grid"

          rows={members}
          columns={columns}
          paginationMode="server"
          rowCount={totalMembers}
          pageSize={pageSize}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            setPage(0); // Reset to first page when page size changes
          }}
          pagination
          rowsPerPageOptions={[5, 50, 100]}
          // onRowClick={handleRowClick} // Add row click handler
          onRowDoubleClick={handleRowClick}
        />
        
      )}
    </div>
  );
};

export default Member_data;
