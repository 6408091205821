import React from "react";
import "./Home.scss"
import Headernav from "../header_nav/Headernav";
import Member_data from "./Members_data";

let Home_page=()=>{
    return(
        <>
        <Headernav/>
        <Member_data/>
        </>
    );
}

export default Home_page;